// import React, { useEffect, useState } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
// } from "@mui/material";
// import Calendar from "react-calendar"; // Install react-calendar if not installed
// import "react-calendar/dist/Calendar.css";
// import { get, patch } from "../../config/axios";

// const AvailabilityModal = ({ open, onClose, doctor }) => {
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [slots, setSlots] = useState([]);

//   useEffect(() => {
//     if (doctor && selectedDate) {
//       fetchDoctorSlots(doctor._id, selectedDate);
//     }
//   }, [doctor, selectedDate]);

//   const fetchDoctorSlots = async (doctorId, date) => {
//     try {
//       const formattedDate = date.toISOString().split("T")[0]; 
//       const response = await get(
//         `/api/dashboard/inPersonFeatures/get-doctor-availability?doctorId=${doctorId}&date=${formattedDate}`
//       );

//       console.log("Fetched Slots:", response.slots); 

//       if (response.slots) {
//         setSlots(response.slots);
//       } else {
//         setSlots([]);
//       }
//     } catch (error) {
//       console.error("Failed to fetch slots", error);
//       setSlots([]); // Avoid undefined issues
//     }
//   };

//   const toggleSlotAvailability = async (slot) => {
//     try {
//       await patch(`/api/doctor/slots/toggle-availability`, {
//         startTime: slot.startTime,
//         endTime: slot.endTime,
//         doctorId: doctor._id,
//         date: selectedDate.toISOString().split("T")[0],
//       });

//       setSlots((prevSlots) =>
//         prevSlots.map((s) =>
//           s.startTime === slot.startTime && s.endTime === slot.endTime
//             ? { ...s, available: !s.available }
//             : s
//         )
//       );
//     } catch (error) {
//       console.error("Error updating slot availability", error);
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>Manage Availability for {doctor?.fullname}</DialogTitle>
//       <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "500px" }}>
//         <Calendar onChange={setSelectedDate} value={selectedDate} />
//         <div
//           style={{
//             marginTop: "20px",
//             display: "flex",
//             flexWrap: "wrap",
//             justifyContent: "center",
//             minHeight: "100px", // Ensures space for slots
//             width: "100%",
//           }}
//         >
//           {slots.length > 0 ? (
//             slots.map((slot, index) => (
//               <Button
//                 key={index}
//                 variant={slot.available ? "outlined" : "contained"}
//                 color={slot.available ? "primary" : "secondary"}
//                 onClick={() => toggleSlotAvailability(slot)}
//                 style={{ margin: "5px", minWidth: "100px" }}
//               >
//                 {slot.startTime} - {slot.endTime}
//               </Button>
//             ))
//           ) : (
//             <p style={{ textAlign: "center", width: "100%" }}>No slots available</p>
//           )}
//         </div>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Close</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AvailabilityModal;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
} from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { get, post } from "../../config/axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MuiAlert from "@mui/material/Alert";

const AvailabilityModal = ({ open, onClose, doctor }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [slots, setSlots] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [pendingForceUpdate, setPendingForceUpdate] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (doctor && selectedDate) {
      fetchDoctorSlots(doctor._id, selectedDate);
    }
  }, [doctor, selectedDate]);

  const fetchDoctorSlots = async (doctorId, date) => {
    try {
        const formattedDate = selectedDate.toLocaleDateString("en-CA");
      const response = await get(
        `/api/dashboard/inPersonFeatures/get-doctor-availability?doctorId=${doctorId}&date=${formattedDate}`
      );

      setSlots(response.slots || []);
    } catch (error) {
      console.error("Failed to fetch slots", error);
      setSlots([]);
    }
  };

  const handleMenuOpen = (event, slot) => {
    setMenuAnchor(event.currentTarget);
    setSelectedSlot(slot);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedSlot(null);
  };

  const handleRemoveSlot = async (removeSeries, forceUpdate = false) => {
    if (!selectedSlot) return;

    try {
        let payload = {
            doctorId: doctor._id,
            date: selectedDate.toLocaleDateString("en-CA"),
            startTime: selectedSlot.startTime,
            endTime: selectedSlot.endTime,
            removeSeries
        };

        if (forceUpdate) {
            payload.forceUpdate = true; // Only add if necessary
        }

        const response = await post(
            `/api/dashboard/inPersonFeatures/mark-slot-unavailable`,
            payload
        );

        if (response.warning && !forceUpdate) {
            setWarningDialogOpen(true);
            setPendingForceUpdate({ removeSeries });
            return;
        }

        setSnackbarMessage(response.message || "Slot updated successfully!");
        fetchDoctorSlots(doctor._id, selectedDate); // Refresh slots
    } catch (error) {
        console.error("Error updating slot", error);
        setSnackbarMessage("Failed to update slot.");
    }

    handleMenuClose();
};


  const handleConfirmForceUpdate = () => {
    if (pendingForceUpdate) {
      handleRemoveSlot(pendingForceUpdate.removeSeries, true);
      setWarningDialogOpen(false);
      setPendingForceUpdate(null);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Manage Availability for {doctor?.fullname}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "500px",
          }}
        >
          <Calendar onChange={setSelectedDate} value={selectedDate} minDate={new Date()}/>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              minHeight: "100px",
              width: "100%",
            }}
          >
            {slots.length > 0 ? (
              slots.map((slot, index) => (
                <div key={index} style={{ position: "relative", margin: "5px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ minWidth: "120px" }}
                  >
                    {slot.startTime} - {slot.endTime}
                  </Button>
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, slot)}
                    style={{ position: "absolute", top: 0, right: -10 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", width: "100%" }}>
                No slots available
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>

        {/* Three-dot Menu */}
        <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleRemoveSlot(false)}>Remove Slot</MenuItem>
          <MenuItem onClick={() => handleRemoveSlot(true)}>Remove Series</MenuItem>
        </Menu>
      </Dialog>

      {/* Confirmation Dialog for Force Update */}
      <Dialog open={warningDialogOpen} onClose={() => setWarningDialogOpen(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          There are confirmed appointments for this slot. Do you want to proceed?
          The confirmed appointments will be canceled.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmForceUpdate} color="secondary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Success/Error Messages */}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage("")}
      >
        <MuiAlert elevation={6} variant="filled" severity="info">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AvailabilityModal;
