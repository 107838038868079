// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import Layout from "../../layout/Main/Layout";
// import CustomTable from "../../components/Custom/Table/CustomTable";
// import { get, put, post, patch } from "../../config/axios";
// import { Button, Typography } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import Searchbar from "../../components/Custom/SearchBar/Searchbar";
// import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
// import { deleteAPI } from "../../helper/apiCallHelper";
// import { doctorTableColumns } from "../../constants/userPage";
// import { useDebouncedValue } from "../../helper/debounce";
// import { toastMessage } from "../../utils/toastMessage";
// import FormModal from "../../components/Custom/FormModal/FormModal";
// import { DoctorFormFields } from "../../constants/userPage";
// import PatientModal from "../Patient/PatientModal";

// const Users = () => {
//   const [users, setUsers] = useState([]);
//   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deleteUser, setDeleteUser] = useState("");
//   const [search, setSearch] = useState("");
//   const [message, setMessage] = useState("");
//   const [page, setPage] = useState(1);
//   const [pageCount, setPageCount] = useState(1);
//   const [totalData, setTotalData] = useState(0);
//   const [perPage, setPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const debouncedSearch = useDebouncedValue(search, 500);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [editModal, setEditModal] = useState(false);
//   const [editData, setEditData] = useState({});

//   const fetchUsers = async (searchValue) => {
//     setLoading(true);
//     await get(
//       `/api/dashboard/dashUser/getAllAppUsers?page=${page}&limit=${10}&search=${searchValue}&userType=DOCTOR`
//     )
//       .then((res) => {
//         setUsers(
//           res?.data.map((item) => ({
//             ...item,
//             action: { edit: true, delete: true },
//           }))
//         );
//         setLoading(false);
//         setPageCount(res?.totalPage);
//         setTotalData(res?.totalData);
//         setPerPage(res?.perPage);
//         setCurrentPage(res?.currentPage);
//       })
//       .catch((err) => {
//         console.log("err", err);
//         setLoading(true);
//       });
//   };

//   useEffect(() => {
//     if (search === "") {
//       fetchUsers("");
//     } else if (debouncedSearch) {
//       fetchUsers(debouncedSearch);
//     }
//   }, [search, debouncedSearch, message, page]);

//   const handleEdit = (row) => {
//     // Implement the edit action for the selected row
//     openModal("edit", row);
//   };

//   const handleDelete = (row) => {
//     openModal("delete", row);
//   };

//   const handleDeleteUser = async (row) => {
//     try {
//       const deletedUser = await patch(
//         `/api/dashboard/dashUser/deleteUser/${row._id}`
//       );
//       setMessage(deletedUser?.message);
//       toastMessage(deletedUser?.message, "success");
//     } catch (error) {
//       console.log("error", error);
//       setMessage("Something went wrong!");
//       toastMessage("Something went wrong!", "error");
//     }
//     setDeleteModalOpen(false);
//   };

//   const handleStatus = (row) => {
//     // Implement the status chnage for the selected row
//     console.log("Delete clicked for row34:", row);
//   };

//   const handleActive = async (id, active) => {
//     setLoading(true);
//     let response = await put(
//       `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
//       {
//         active: active,
//       }
//     );
//     setLoading(false);
//     const username = response.data.fullname;
//     const message = active
//       ? `${username} has been successfully activated.`
//       : `${username} has been successfully deactivated.`;

//     setMessage(message);
//     toastMessage(message, "success");
//   };

//   const handleSearch = (searchText) => {
//    setSearch(searchText.trim());
//     setPage(1);
//   };

//   const handleCloseDeleteModal = () => {
//     setDeleteModalOpen(false);
//   };

//   const handleChange = (page) => {
//     setPage(page);
//   };

//   const openModal = (type, dataForEdit) => {
//     if (type === "add") {
//       setIsModalOpen(true);
//     } else if (type === "edit") {
//       setEditModal(true);
//       setEditData(dataForEdit);
//     } else if (type === "delete") {
//       setDeleteModalOpen(true);
//       setDeleteUser(dataForEdit);
//     }
//   };

//   const closeModal = (type) => {
//     if (type === "add") {
//       setIsModalOpen(false);
//     } else if (type === "edit") {
//       setEditModal(false);
//       setEditData({});
//     }
//   };

//   const handleSubmit = async (formData, isEditing, id) => {
//     setLoading(true);
//     try {
//       if (isEditing) {
//         const { ...data } = formData;
//         let response = await put(
//           `/api/dashboard/dashUser/updateAppAccount?id=${id}`,
//           data
//         );
//         if (!response || response.status === 400) {
//           toastMessage(response.message.message, "error");
//         } else {
//           const username = response.data.fullname;
//           const message = `${username} has been updated.`;
//           setMessage(message);
//           toastMessage(message, "success");
//         }
//       } else {
//         formData = {
//           ...formData,
//           userType: "DOCTOR",
//           password: "12345678",
//         };
//         const { ...data } = formData;
//         console.log(data);
//         const res = await post("/api/dashboard/dashUser/addAccount", data);

//         if (!res || res.status === 400) {
//           toastMessage(res.message.message, "error");

//           setEditData({});

//         } else {
//           setMessage("Successfully added");
//           toastMessage(res.data.message, "success");
//           setEditData({});
//           setIsModalOpen(false);
//         }
//       }
//     } catch (err) {
//       console.error("Error:", err);
//       setMessage("Error while processing the request");
//       toastMessage("Error while updating", "error");
//     }
//     setLoading(false);
//   };

//   return (
//     <>
//       <Layout>
//         <div style={{ padding: "1rem" }}>
//           <Typography variant="h5">Doctor's Detail</Typography>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <div style={{ width: "40%" }}>
//               <Searchbar
//                 search={handleSearch}
//                 placeholder={"Search by full name or phone number "}
//                 debounceTime={1000}
//               />
//             </div>

//             <Button
//               onClick={() => openModal("add")}
//               variant="outlined"
//               startIcon={<AddIcon fontSize="large" />}
//               style={{ fontWeight: "bold" }}
//             >
//               add doctor
//             </Button>
//           </div>
//           <CustomTable
//             data={users}
//             columns={doctorTableColumns}
//             handleEdit={handleEdit}
//             handleDelete={handleDelete}
//             handleStatus={handleStatus}
//             handleActive={(row, active) => handleActive(row, active)}
//             handlePageChange={(page) => handleChange(page)}
//             pageNumber={page}
//             pageCount={pageCount}
//             totalData={totalData}
//             perPage={perPage}
//             currentPage={currentPage}
//             loading={loading}
//           />
//         </div>
//       </Layout>
//       <DeleteModal
//         open={isDeleteModalOpen}
//         onClose={handleCloseDeleteModal}
//         onDelete={handleDeleteUser}
//         data={deleteUser}
//       />
//       {/* common modal for patient and doctor */}
//       <PatientModal 
//         isOpen={isModalOpen || editModal}
//         onClose={() => closeModal(editModal ? "edit" : "add")}
//         onSubmit={handleSubmit}
//         fields={DoctorFormFields}
//         header={editModal ? "Edit Doctor" : "Add Doctor"}
//         initialData={editData}
//         isEditing={editModal}
//       />
//     </>
//   );
// };

// export default Users;
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import { Button, Typography } from "@mui/material";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, postFiles, put, post, patch } from "../../config/axios";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import { deleteAPI } from "../../helper/apiCallHelper";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { toastMessage } from "../../utils/toastMessage";
import AddIcon from "@mui/icons-material/Add";
import FormModal from "../../components/Custom/FormModal/FormModal";
// import {
//   blogSubCategoriesFormFields,
//   blogSubCategoriesTableColumns,
// } from "../../constants/blogSubCategories";
import { useDebouncedValue } from "../../helper/debounce";
const EventSubCategories = () => {
  const [eventSubCategories, setEventSubCategories] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [gutSubCategoriesTableColumns, setGutSubCategoriesTableColumns] =
    useState([
      {
        id: "title",
        label: "Sub Category Name(In English)",
        minWidth: 70,
        align: "left",
      },
      {
        id: "hindiTitle",
        label: "Sub Category Name(In Hindi)",
        minWidth: 70,
        align: "left",
      },
      // {
      //   id: "category",
      //   label: "Category Name",
      //   minWidth: 70,
      //   align: "left",
      // },
      {
        id: "categoryName",
        label: "Category Name",
        minWidth: 70,
        align: "left",
      },
      // {
      //   id: "asset",
      //   label: "Icon",
      //   minWidth: 70,
      //   align: "center",
      //   type: "IMAGE",
      // },
      {
        id: "active",
        label: "Active",
        minWidth: 70,
        align: "center",
      },
      {
        id: "action",
        label: "Action",
        minWidth: 100,
        align: "center",
      },
    ]);
  const [gutSubCategoriesFormFields, setGutSubCategoriesFormFields] = useState([
    {
      name: "category",
      label: "Category",
      type: "subCategory",
      // isMultiSelect: false,
      // category: "category",
      options: [],
      required: true,
    },
    { name: "title", label: "SubCategory Name(In English)", type: "text", required: true },
    {
      name: "hindiTitle",
      label: "SubCategory Name(In Hindi)",
      type: "text",
      required: true,
    },
    // {
    //   name: "asset",
    //   label: "SubCategory Icon (.svg)",
    //   type: "file",
    //   required: true,
    // }
  ]);
  const debouncedSearch = useDebouncedValue(search, 500);
  const fetchEventSubCategories = async (searchValue) => {
    await get(
      `/api/dashboard/apputility/getSubCategory?search=${searchValue}&page=${page}&limit=${10}&type=GUT_VIDEOS`
    )
      .then((res) => {
        setEventSubCategories(
          res?.data.map((item) => ({
            ...item,
            category: item.category ? item.category?._id : "",
            categoryName: item.category ? item.category?.title : "",
            action: { edit: true, delete: true },
          }))
        );
        setPageCount(res?.totalPage);
        setTotalData(res?.totalData);
        setPerPage(res?.perPage);
        setCurrentPage(res?.currentPage);
        setMessage(res?.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };
  const fetchEventCategories = async () => {
    const res = await get(
      "/api/dashboard/apputility/getCategory?type=GUT_VIDEOS&totalData=true"
    );
    // setEventCategories(res?.data);
    setGutSubCategoriesFormFields((prev) =>
      prev?.map((field) => {
        if (field.name === "category") {
          return { ...field, options: res?.data };
        }
        return field;
      })
    );
  };
  useEffect(() => {
    if (search === "") {
      fetchEventSubCategories("");
      fetchEventCategories();
    } else if (debouncedSearch) {
      fetchEventSubCategories(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);
  const handleStatus = (row) => {
    console.log("Delete clicked for row34:", row);
  };
  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };
  const handleDelete = (row) => {
    openModal("delete", row);
  };
  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/apputility/updateSubCategory?id=${id}`,
      {
        active: active,
      }
    );
    const subCategoryTitle = response?.data?.title;
    const message = active
      ? `${subCategoryTitle} successfully activated.`
      : `${subCategoryTitle} successfully deactivated.`;
    setLoading(false);
    setMessage(message);
    toastMessage(message, "success");
  };
  const handleDeleteEventCategory = async (row) => {
    try {
      const deletedCategory = await patch(
        `/api/dashboard/apputility/deleteSubCategory/${row._id}`
      );
      setMessage(deletedCategory?.message);
      toastMessage(deletedCategory?.message, "success");
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };
  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };
  const handleSubmit = async (formData, isEditing, id) => {
    setLoading(true);
    try {
      if (isEditing) {
        let form = new FormData();
        const { ...data } = formData;
        // if(formData.asset instanceof File){
        //   form.append("file", formData?.asset);
        //   const res = await postFiles("/api/app/user/uploadImage", form);
        //   data.asset = res.data.url;
        // }
        let response = await put(
          `/api/dashboard/apputility/updateSubCategory?id=${id}`,
          data
        );
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        // formData = {
        //   ...formData,
        //   type: "BLOGS",
        // };
        const { ...data } = formData;
        // let form = new FormData();
        // form.append("file", formData?.asset);
        // const res = await postFiles("/api/app/user/uploadImage", form);
        // data.asset = res.data.url;
        data.asset =
          "https://applore-dev-projects-1.s3.ap-south-1.amazonaws.com/public/ck2eqck2eqmedical-care.svg"; // it will be removed later
        await post("/api/dashboard/appUtility/addSubCategory", data);
        setMessage("Successfully added");
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };
  const handleChange = (page) => {
    setPage(page);
  };
  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Gut Sub Categories</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by sub category name"}
                debounceTime={1000}
              />
            </div>
            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add gut sub category
            </Button>
          </div>
          <CustomTable
            data={eventSubCategories}
            columns={gutSubCategoriesTableColumns}
            handleEdit={(row) => openModal("edit", row)}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteEventCategory}
        data={deleteUser}
      />
      <FormModal
        accept="image/*"
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={gutSubCategoriesFormFields}
        header={editModal ? "Edit Gut Subcategory" : "Add Gut Subcategory"}
        initialData={editData}
        isEditing={editModal}
      />
    </>
  );
};
export default EventSubCategories;