import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import logo1 from "../../assets/images/GutBuddy2.svg";
import GroupIcon from "@mui/icons-material/Group";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LandscapeIcon from "@mui/icons-material/Landscape";
import LayersIcon from "@mui/icons-material/Layers";
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InsightsIcon from '@mui/icons-material/Insights';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BookIcon from '@mui/icons-material/Book';
import DoctorImg from "../../assets/images/medical-assistance.svg"; // Import the DoctorImg SVG
export const sidebarListArr = [
  { label: "Patient List", icon: <GroupIcon />, url: "/Patient" },
  { label: "Doctor List", imageIcon: DoctorImg, url: "/doctor" }, // Update for DoctorImg
  {label:  "Doctor Speciality", icon: <InsightsIcon />, url: "/doctorspeciality" },
  // {label:  "In Person Appointment", icon: <BookIcon />, url: "/inpersonappointment" },
  { label: "Diet Content", icon: <DinnerDiningIcon />, url: "/diet" },
  { label: "Gut Content", icon: <PsychologyIcon />, url: "/brain" },
  { label: "Learn Content", icon: <RssFeedIcon />, url: "/blogContnent" },
  { label: "FAQ", icon: <LiveHelpIcon />, url: "/faq" },
  { label: "Test Reports", icon: <SummarizeIcon />, url: "/reports" },
  { label: "Diet Categories", icon: <LandscapeIcon />, url: "/dietcategories" },
  { label: "Gut Categories", icon: <LayersIcon />, url: "/gutcategories" },
  { label: "Learn Categories", icon: <DashboardIcon />, url: "/blogcategories" },
  { label: "Learn Sub Categories", icon: <EventNoteIcon />, url: "/blogsubcategories" },
  { label: "Diet Sub Categories", icon: <FactCheckIcon />, url: "/dietsubcategories" },
  { label: "Gut Sub Categories", icon: <FeaturedPlayListIcon />, url: "/gutsubcategories" },
  // { label: "Yoga Module", icon: <SelfImprovementIcon />, url: "/yogamodule" },
  { label: "User Feedback", icon: <FeedbackIcon />, url: "/feedback" },
];
const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location", location.pathname);
  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <Box className={styles.main_div}>
      <Box className={styles.logo_content}>
      <Box className={styles.header_div}>
        <img src={logo1} alt="logo" className={styles.logo} />
      </Box>
      {/* <Typography className={styles.logo_heading}>GUT-BUDDY</Typography> */}
      </Box>
      <List className={styles.list_div}>
        {sidebarListArr.map((val, index) => (
          <div
            key={index}
            className={
              location.pathname === val?.url
                ? styles.active_icon
                : styles.inactive_icon
            }
          >
            <NavLink
              to={`${val.url}`}
              style={{ textDecoration: "none", color: "darkgray" }}
            >
              <ListItem button key={val.label}>
                <ListItemIcon color="inherit" className={styles.icon_css}>
                  {/* Render the icon or imageIcon */}
                  {val.icon ? (
                    val.icon
                  ) : (
                    <img
                      src={val.imageIcon}
                      alt={val.label}
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={val.label}
                  className={
                    location.pathname === val?.url
                      ? styles.active_url_text
                      : styles.inactive_url_text
                  }
                />
              </ListItem>
            </NavLink>
          </div>
        ))}
      </List>
      <Box className={styles.logout_div}>
        <Button
          onClick={logoutHandler}
          variant="contained"
          className={styles.logout_btn}
        >
          logout
        </Button>
      </Box>
    </Box>
  );
};
export default Sidebar;


// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Typography,
//   Collapse,
// } from "@mui/material";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import styles from "./Sidebar.module.css";
// import logo1 from "../../assets/images/GutBuddy2.svg";

// import GroupIcon from "@mui/icons-material/Group";
// import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
// import PsychologyIcon from "@mui/icons-material/Psychology";
// import RssFeedIcon from "@mui/icons-material/RssFeed";
// import LiveHelpIcon from "@mui/icons-material/LiveHelp";
// import SummarizeIcon from "@mui/icons-material/Summarize";
// import LandscapeIcon from "@mui/icons-material/Landscape";
// import LayersIcon from "@mui/icons-material/Layers";
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import EventNoteIcon from '@mui/icons-material/EventNote';
// import FactCheckIcon from '@mui/icons-material/FactCheck';
// import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
// import FeedbackIcon from '@mui/icons-material/Feedback';
// import InsightsIcon from '@mui/icons-material/Insights';
// import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
// import BookIcon from '@mui/icons-material/Book';
// import DoctorImg from "../../assets/images/medical-assistance.svg"; // Import the DoctorImg SVG
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import PremiumIcon from '@mui/icons-material/Workspaces';

// // Premium modules configuration
// const premiumModules = [
//   {
//     label: "Yoga Module",
//     icon: <SelfImprovementIcon />,
//     url: "/yogamodule",
//     subModules: [
//       { label: "Yoga", url: "/yogamodule" },
//       // Add more yoga-related submodules here
//     ]
//   },
//   {
//     label: "In-person Module",
//     icon: <BookIcon />,
//     url: "/inpersonappointment",
//     subModules: [
//       { label: "In-person Appointment", url: "/inpersonappointment" },
//     ]
//   },
//   // Add more premium modules as needed
// ];

// export const sidebarListArr = [
//   { label: "Patient List", icon: <GroupIcon />, url: "/Patient" },
//   { label: "Doctor List", imageIcon: <DoctorImg />, url: "/doctor" }, // Update for DoctorImg
//   {label:  "Doctor Speciality", icon: <InsightsIcon />, url: "/doctorspeciality" },
//   // {label:  "In Person Appointment", icon: <BookIcon />, url: "/inpersonappointment" },
//   { label: "Diet Content", icon: <DinnerDiningIcon />, url: "/diet" },
//   { label: "Gut Content", icon: <PsychologyIcon />, url: "/brain" },
//   { label: "Learn Content", icon: <RssFeedIcon />, url: "/blogContnent" },
//   { label: "FAQ", icon: <LiveHelpIcon />, url: "/faq" },
//   { label: "Test Reports", icon: <SummarizeIcon />, url: "/reports" },
//   { label: "Diet Categories", icon: <LandscapeIcon />, url: "/dietcategories" },
//   { label: "Gut Categories", icon: <LayersIcon />, url: "/gutcategories" },
//   { label: "Learn Categories", icon: <DashboardIcon />, url: "/blogcategories" },
//   { label: "Learn Sub Categories", icon: <EventNoteIcon />, url: "/blogsubcategories" },
//   { label: "Diet Sub Categories", icon: <FactCheckIcon />, url: "/dietsubcategories" },
//   { label: "Gut Sub Categories", icon: <FeaturedPlayListIcon />, url: "/gutsubcategories" },
//   // { label: "Yoga Module", icon: <SelfImprovementIcon />, url: "/yogamodule" },
//   { label: "User Feedback", icon: <FeedbackIcon />, url: "/feedback" },
// ];

// // const Sidebar = () => {
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   console.log("location", location.pathname);
// //   const logoutHandler = () => {
// //     localStorage.removeItem("token");
// //     navigate("/login");
// //   };

// //   return (
// //     <Box className={styles.main_div}>
// //       <Box className={styles.logo_content}>
// //       <Box className={styles.header_div}>
// //         <img src={logo1} alt="logo" className={styles.logo} />
// //       </Box>
// //       {/* <Typography className={styles.logo_heading}>GUT-BUDDY</Typography> */}
// //       </Box>

// //       <List className={styles.list_div}>
// //         {sidebarListArr.map((val, index) => (
// //           <div
// //             key={index}
// //             className={
// //               location.pathname === val?.url
// //                 ? styles.active_icon
// //                 : styles.inactive_icon
// //             }
// //           >
// //             <NavLink
// //               to={`${val.url}`}
// //               style={{ textDecoration: "none", color: "darkgray" }}
// //             >
// //               <ListItem button key={val.label}>
// //                 <ListItemIcon color="inherit" className={styles.icon_css}>
// //                   {/* Render the icon or imageIcon */}
// //                   {val.icon ? (
// //                     val.icon
// //                   ) : (
// //                     <img
// //                       src={val.imageIcon}
// //                       alt={val.label}
// //                       style={{ width: "24px", height: "24px" }}
// //                     />
// //                   )}
// //                 </ListItemIcon>
// //                 <ListItemText
// //                   primary={val.label}
// //                   className={
// //                     location.pathname === val?.url
// //                       ? styles.active_url_text
// //                       : styles.inactive_url_text
// //                   }
// //                 />
// //               </ListItem>
// //             </NavLink>
// //           </div>
// //         ))}
// //       </List>
// //       <Box className={styles.logout_div}>
// //         <Button
// //           onClick={logoutHandler}
// //           variant="contained"
// //           className={styles.logout_btn}
// //         >
// //           logout
// //         </Button>
// //       </Box>
// //     </Box>
// //   );
// // };

// // export default Sidebar;

// const Sidebar = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [premiumOpen, setPremiumOpen] = useState(false);
//   const [openModules, setOpenModules] = useState({});

  
//   // Modified to check if current path is part of premium modules
//   const isPremiumPath = () => {
//     return premiumModules.some(module => 
//       location.pathname.startsWith(module.url) ||
//       module.subModules.some(sub => location.pathname === sub.url)
//     );
//   };

//   // Keep premium dropdown open if current path is a premium path
//   React.useEffect(() => {
//     if (isPremiumPath()) {
//       setPremiumOpen(true);
      
//       // Find and open the relevant module
//       premiumModules.forEach(module => {
//         if (location.pathname.startsWith(module.url)) {
//           setOpenModules(prev => ({
//             ...prev,
//             [module.label]: true
//           }));
//         }
//       });
//     }
//   }, [location.pathname]);
//   const handlePremiumClick = () => {
//     setPremiumOpen(!premiumOpen);
//   };

//   const handleModuleClick = (moduleLabel) => {
//     setOpenModules(prev => ({
//       ...prev,
//       [moduleLabel]: !prev[moduleLabel]
//     }));
//   };

//   const logoutHandler = () => {
//     localStorage.removeItem("token");
//     navigate("/login");
//   };

//   // Updated isActive function to do exact path matching
//   const isActive = (path) => {
//     // For root path, only match exactly
//     if (path === '/') {
//       return location.pathname === path;
//     }
    
//     // For all other paths, do exact matching
//     return location.pathname === path;
//   };
//   return (
//     <Box className={styles.main_div}>
//     <Box className={styles.logo_content}>
//       <Box className={styles.header_div}>
//         <img src={logo1} alt="logo" className={styles.logo} />
//       </Box>
//     </Box>

//     <List className={styles.list_div}>
//       {/* Premium Dropdown */}
//       <ListItem 
//         button 
//         onClick={handlePremiumClick}
//         className={isPremiumPath() ? styles.active_icon : styles.inactive_icon}
//       >
//         <ListItemIcon className={styles.icon_css}>
//           <PremiumIcon />
//         </ListItemIcon>
//         <ListItemText primary="Premium" />
//         {premiumOpen ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>

//       <Collapse in={premiumOpen} timeout="auto" unmountOnExit>
//         <List component="div" disablePadding>
//           {premiumModules.map((module, index) => (
//             <div key={index}>
//               <ListItem
//                 button
//                 onClick={() => handleModuleClick(module.label, module.url)}
//                 className={isActive(module.url) ? styles.active_icon : styles.inactive_icon}
//                 sx={{ pl: 4 }}
//               >
//                 <ListItemIcon className={styles.icon_css}>
//                   {module.icon}
//                 </ListItemIcon>
//                 <ListItemText primary={module.label} />
//                 {module.subModules?.length > 0 && (
//                   openModules[module.label] ? <ExpandLess /> : <ExpandMore />
//                 )}
//               </ListItem>

//               {module.subModules?.length > 0 && (
//                 <Collapse in={openModules[module.label]} timeout="auto" unmountOnExit>
//                   <List component="div" disablePadding>
//                     {module.subModules.map((subModule, subIndex) => (
//                       <NavLink
//                         key={subIndex}
//                         to={subModule.url}
//                         style={{ textDecoration: "none", color: "darkgray" }}
//                       >
//                         <ListItem 
//                           button 
//                           sx={{ pl: 6 }}
//                           className={isActive(subModule.url) ? styles.active_icon : styles.inactive_icon}
//                         >
//                           <ListItemText primary={subModule.label} />
//                         </ListItem>
//                       </NavLink>
//                     ))}
//                   </List>
//                 </Collapse>
//               )}
//             </div>
//           ))}
//         </List>
//       </Collapse>

//         {/* Regular sidebar items */}
//         {sidebarListArr.map((val, index) => (
//           <div
//             key={index}
//             className={isActive(val.url) ? styles.active_icon : styles.inactive_icon}
//           >
//             <NavLink
//               to={val.url}
//               style={{ textDecoration: "none", color: "darkgray" }}
//             >
//               <ListItem button>
//                 <ListItemIcon className={styles.icon_css}>
//                   {/* Render the icon or imageIcon */}
//                   {val.icon ? (
//                     val.icon
//                   ) : (
//                     <img
//                       src={val.imageIcon.type}
//                       alt={val.label}
//                       style={{ width: "24px", height: "24px" }}
//                     />
//                   )
//                   }
                  
//                 </ListItemIcon>
//                 <ListItemText
//                   primary={val.label}
//                   className={isActive(val.url) ? styles.active_url_text : styles.inactive_url_text}
//                 />
//               </ListItem>
//             </NavLink>
//           </div>
//         ))}
//       </List>

//       <Box className={styles.logout_div}>
//         <Button
//           onClick={logoutHandler}
//           variant="contained"
//           className={styles.logout_btn}
//         >
//           logout
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default Sidebar;