/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, postFiles, patch } from "../../config/axios";
import {
  Button,
  Box,
  Modal,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import {
  blogContentTableColumns,
  blogContentFormFields,
} from "../../constants/blogContentPage";
import styles from "./BlogContent.module.css";
import moment from "moment";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({}); ///
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [blogContentFormFields, setBlogContentFormFields] = useState([
    {
      name: "title",
      label: "Title",
      type: "text",
      required: true,
    },
    {
      name: "category",
      label: "Category",
      type: "category",
      isMultiSelect: false,
      options: [],
      required: true,
    },
    {
      name: "subCategory",
      label: "SubCategory",
      type: "subCategory",
      isMultiSelect: false,
      options: [],
      required: true,
    },
    {
      name: "assets",
      label: "Attachment",
      type: "file",
      required: false,
    },
    {
      name: "thumbnail",
      label: "Thumbnail",
      type: "file",
      required: false,
    },
    {
      name: "authorName",
      label: "Author Name",
      type: "text",
      required: true,
    },
    {
      name: "readTime",
      label: "Read Time",
      type: "number",
      required: true,
    },
    {
      name: "languages",
      label: "Languages",
      type: "option",
      isMultiSelect: false,
      options: ["HINDI", "ENGLISH"],
      // required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "description",
      required: true,
    },
  ]);

  const fetchUsers = async (searchValue) => {
    try {
      setLoading(true);
      const res = await get(
        `/api/dashboard/apputility/getAppContent?page=${page}&limit=${10}&search=${searchValue}&type=BLOGS`
      );
      const response = await get(
        `api/dashboard/apputility/getCategory?type=BLOGS&totalData=true`
      );
      // console.log("getAppcontent", res);
      // console.log("getcategory",response);
      setBlogContentFormFields((prev) =>
        prev.map((field) => {
          if (field.name === "category") {
            return { ...field, options: response?.data };
          }
          return field;
        })
      );
      setUsers(
        res?.data.map((item) => ({
          ...item,
          action: { edit: true, delete: true },
          categoryTitle: item.category ? item.category?.title : "",
          subCategoryTitle: item.subCategory ? item.subCategory?.title : "",
        }))
      );
      fetchSubCategories();
      setLoading(false);
      setPageCount(res?.totalPage);
    } catch (err) {
      console.error("Error:", err);
      setLoading(true);
    }
  };

  const fetchSubCategories = async () => {
    const res = await get(
      `/api/dashboard/apputility/getSubCategory?category=${selectedCategory}`
    );
    setBlogContentFormFields((prev) =>
      prev.map((field) => {
        if (field.name === "subCategory") {
          return { ...field, options: res?.data };
        }
        return field;
      })
    );
  };
  useEffect(() => {
    fetchSubCategories();
  }, [selectedCategory]);

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    // Implement the edit action for the selected row
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedDietContent = await patch(
        `/api/dashboard/apputility/deleteAppContent/${row._id}`
      );
      setMessage(deletedDietContent?.message);
      toastMessage(deletedDietContent?.message, "success");
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/apputility/updateAppContent?id=${id}`,
      {
        active: active,
      }
    );
    setLoading(false);
    const title = response.data.title;
    const message = active
      ? `${title} has been successfully activated.`
      : `${title} has been successfully deactivated.`;
    setMessage(message);
    toastMessage(message, "success");
  };

  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleDisplay = (row) => {
    // Implement the edit action for the selected row
    console.log("Display", row);
    setViewData(row);
    setViewModal(true);
  };

  const handleSubmit = async (formData, isEditing, id) => {
    console.log("formdata", formData);
    try {
      if (isEditing) {
        setLoading(true);
        // if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
        // }
        const { ...data } = formData;
        if (formData.assets instanceof File) {
          let form = new FormData();
          form.append("file", formData?.assets);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.assets = res?.data?.url;
        }
        if (formData.thumbnail instanceof File) {
          let form = new FormData();
          form.append("file", formData?.thumbnail);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.thumbnail = res?.data?.url;
        }
        let response = await put(
          `/api/dashboard/apputility/updateAppContent?id=${id}`,
          data
        );
        setLoading(false);
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        // Check if the required file fields are empty
        if (!formData.assets || (formData.assets && formData.assets.length === 0)) {
          setLoading(false);
          toastMessage("Attachment is required!", "error");
          return;
        }
        if (!formData.thumbnail || (formData.thumbnail && formData.thumbnail.length === 0)) {
          setLoading(false);
          toastMessage("Thumbnail is required!", "error");
          return;
        // } if (!formData.assets.type.startsWith("video/")) {
        //   setLoading(false);
        //   toastMessage("Attachment should be a video!", "error");
        //   return;
        // }
        // if (!formData.thumbnail.type.startsWith("image/")) {
        //   setLoading(false);
        //   toastMessage("Thumbnail should be an image!", "error");
        //   return;
        // }
        }
        setLoading(true);
        let form = new FormData();
        form.append("file", formData?.assets);
        const res = await postFiles("/api/app/user/uploadImage", form);
        let form2 = new FormData();
        form2.append("file", formData?.thumbnail);
        const res2 = await postFiles("/api/app/user/uploadImage", form2);
        const { ...data } = formData;
        data.assets = res.data.url;
        data.thumbnail = res2.data.url;
        await post("/api/dashboard/apputility/addAppContent", data);
        setLoading(false);
        setMessage("Successfully added");
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setLoading(false);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
  };

  const handlePreview = (url) => {
    if (!url) return;
    setPreviewUrl(url);
    setPreviewModal(true);
  };

  const handleDownload = async (url) => {
    try {
      if (!url) {
        toastMessage("No file available for download", "error");
        return;
      }
  
      // Handle case where URL might be an array
      const downloadUrl = Array.isArray(url) ? url[0] : url;
  
      if (typeof downloadUrl !== "string" || !downloadUrl.startsWith("http")) {
        toastMessage("Invalid file URL", "error");
        return;
      }
  
      // Extract filename from URL or generate one
      const filename = downloadUrl.split('/').pop() || `download-${Date.now()}`;
      
      // Fetch the file first
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
  
      // Create hidden anchor element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      link.style.display = 'none';
      
      // Append to body and trigger click
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
  
    } catch (error) {
      console.error("Download failed:", error);
      toastMessage("Download failed. Please try again.", "error");
      
      // Fallback to normal download if fetch fails
      const fallbackLink = document.createElement('a');
      fallbackLink.href = url;
      fallbackLink.download = url.split('/').pop();
      document.body.appendChild(fallbackLink);
      fallbackLink.click();
      document.body.removeChild(fallbackLink);
    }
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Learn content</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by title"}
                debounceTime={1000}
              />
            </div>

            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add new blog
            </Button>
          </div>

          <CustomTable
            data={users}
            columns={blogContentTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            handleDisplay={handleDisplay}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      <FormModal
        accept="image/*, video/*"
        setSelectedCategory={setSelectedCategory}
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={blogContentFormFields}
        header={editModal ? "Edit Blog" : "Add Blog"}
        initialData={editData}
        isEditing={editModal}
      />

<Modal
        open={viewModal}
        onClose={() => {
          setViewModal(false);
          setViewData({});
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "90vh",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => {
              setViewModal(false);
              setViewData({});
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" sx={{ p: 1, textAlign: "center" }}>
            Blog Details
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th style={{ textAlign: "left", padding: "8px" }}>Type</th>
                  <th style={{ textAlign: "left", padding: "8px" }}>Details</th>
                </tr>
              </thead>

              <tbody>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Title</td>
                  <td style={{ padding: "8px" }}>{viewData?.title || "-"}</td>
                </tr>
                
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Description</td>
                  <td style={{ padding: "8px" }}>
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {viewData?.description || "-"}
                    </div>
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Attachment</td>
                  <td style={{ padding: "8px" }}>
                    {viewData?.assets ? (
                      <div 
                        onClick={() => handlePreview(viewData?.assets)}
                        style={{ cursor: "pointer" }}
                      >
                        {viewData?.assets.toString().includes("mp4") ? (
                          <div style={{
                            width: "150px",
                            height: "100px",
                            overflow: "hidden",
                            backgroundColor: "#000",
                            position: "relative"
                          }}>
                            <video
                              src={viewData?.assets}
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                              muted
                            />
                            <PlayArrowIcon
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#fff",
                                fontSize: "40px"
                              }}
                            />
                          </div>
                        ) : viewData?.assets.toString().includes("pdf") ? (
                          <div style={{
                            width: "150px",
                            height: "100px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            gap: "8px"
                          }}>
                            <img
                              src="/assets/pdf.svg"
                              alt="PDF document"
                              style={{ width: "50px", height: "50px" }}
                            />
                            <span style={{ fontSize: "12px" }}>View PDF</span>
                          </div>
                        ) : (
                          <img
                            src={viewData?.assets}
                            alt="Blog attachment"
                            style={{ 
                              width: "150px", 
                              height: "100px", 
                              objectFit: "cover",
                              backgroundColor: "#f5f5f5"
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/assets/fallback-image.svg";
                            }}
                          />
                        )}
                      </div>
                    ) : "-"}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Thumbnail</td>
                  <td style={{ padding: "8px" }}>
                    {viewData?.thumbnail ? (
                      <div 
                        onClick={() => handlePreview(viewData?.thumbnail)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={viewData?.thumbnail}
                          alt="Blog thumbnail"
                          style={{ 
                            width: "150px", 
                            height: "100px", 
                            objectFit: "cover",
                            backgroundColor: "#f5f5f5"
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/fallback-image.svg";
                          }}
                        />
                      </div>
                    ) : "-"}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Category</td>
                  <td style={{ padding: "8px" }}>{viewData?.category?.title || "-"}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>SubCategory</td>
                  <td style={{ padding: "8px" }}>{viewData?.subCategory?.title || "-"}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Author Name</td>
                  <td style={{ padding: "8px" }}>{viewData?.authorName || "-"}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Read Time</td>
                  <td style={{ padding: "8px" }}>{viewData?.readTime || "-"}</td>
                </tr>

                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", fontWeight: "bold" }}>Publishing Date</td>
                  <td style={{ padding: "8px" }}>
                    {viewData?.createdAt
                      ? moment.utc(viewData.createdAt).format("DD-MM-YYYY")
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>

            <Button
              variant="contained"
              sx={{ mt: 2, alignSelf: "flex-end" }}
              onClick={() => {
                setViewModal(false);
                setViewData({});
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Preview Modal */}
      <Dialog
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent style={{ height: "80vh", position: "relative" }}>
          {previewUrl ? (
            previewUrl.toString().includes(".pdf") ? (
              <div style={{ position: "relative", height: "100%" }}>
                <CircularProgress 
                  style={{ 
                    position: "absolute", 
                    top: "50%", 
                    left: "50%", 
                    transform: "translate(-50%, -50%)" 
                  }} 
                />
                <iframe
                  onLoad={(e) => e.target.previousSibling.style.display = "none"}
                  src={`https://docs.google.com/viewer?url=${encodeURIComponent(previewUrl)}&embedded=true`}
                  title="PDF Preview"
                  style={{ width: "100%", height: "100%", border: "none" }}
                />
              </div>
            ) : previewUrl.toString().includes(".mp4") ? (
              <video 
                controls 
                autoPlay
                style={{ width: "100%", height: "100%" }}
              >
                <source src={previewUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "contain",
                  backgroundColor: "#f5f5f5"
                }}
              />
            )
          ) : (
            <CircularProgress style={{ margin: "auto", display: "block" }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewModal(false)} color="secondary">
            Close
          </Button>
          <Button
            onClick={() => handleDownload(previewUrl)}
            color="primary"
            variant="contained"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
