export const reportTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Upload Date",
    minWidth: 70,
    align: "center",
  },
  {
    id: "user.fullname",
    label: "User Name",
    minWidth: 70,
    align: "center",
  },
  {
    id: "user.phone",
    label: "User Phone",
    minWidth: 70,
    align: "center",
  },
  {
    id: "_id",
    label: "Document ID",
    minWidth: 70,
    align: "center",
  },
  // {
  //   id: "name",
  //   label: "Document Name",
  //   minWidth: 70,
  //   align: "center",
  // },
  // {
  //   id: "labName",
  //   label: "Lab Name",
  //   minWidth: 70,
  //   align: "center",
  // },
  {
    id: "testType",
    label: "Types of Test",
    minWidth: 70,
    align: "center",
  },
  {
    id: "document",
    label: "Attachment",
    minWidth: 70,
    align: "center",
  },
  {
    id: "ibsAssessment.assessmentType",
    label: "Assessment Type",
    minWidth: 70,
    align: "center",
  },
  {
    id: "ibsAssessment.assessmentDate",
    label: "Assessment Date",
    minWidth: 70,
    align: "center",
  },
  {
    id: "ibsAssessment.fileLocation",
    label: "Assessment File",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  }
  // {
  //   id: "title",
  //   label: "Test Name",
  //   minWidth: 70,
  //   align: "center",
  // },
  // {
  //   id: "createdAt",
  //   label: "Date",
  //   minWidth: 70,
  //   align: "center",
  //   type: "date",
  // },
];
export const reportFormFields = [
  {
    name: "title",
    label: "Test Name",
    type: "text",
    required: true,
  },
  {
    name: "docId",
    label: "Document Id",
    type: "text",
    required: true,
  },
  {
    name: "CreatedAt",
    label: "Date",
    type: "date",
    required: true,
  },
  // {
  //   name: "image",
  //   label: "Category Image (.jpeg .jpg .png)",
  //   type: "file",
  //   required: true,
  // },
  // {
  //   id: "image",
  //   label: "Image",
  //   minWidth: 70,
  //   align: "center",
  //   type: "IMAGE",
  // },

  // {
  //   name: "category",
  //   label: "Category",
  //   type: "text",
  //   isMultiSelect: false,
  //   options: ["Diet", "Brain"],
  //   required: false,
  // },
  // {
  //   name: "category",
  //   label: "Category",
  //   type: "text",
  //   isMultiSelect: false,
  //   category: "category",
  //   options: [],
  //   required: true,
  // },
  // {
  //   name: "assets",
  //   label: "Image",
  //   type: "text",
  //   required: true,
  // },
  // {
  //   name: "authorName",
  //   label: "Author Name",
  //   type: "text",
  //   requied: true,
  // },
  // {
  //   name: "readTime",
  //   label: "Read Time",
  //   type: "text",
  //   required: true,
  // },
  // {
  //   name: "createdAt",
  //   label: "Publishing Date",
  //   type: "date",
  //   required: false,
  // },
];
