/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, postFiles, patch } from "../../config/axios";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import {
  reportTableColumns,
  reportFormFields,
} from "../../constants/ReportsPage";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);

  const fetchUsers = async (searchValue) => {
    try {
      console.log("vgbhjnkm");
      setLoading(true);
      const res = await get(
        `/api/dashboard/appFeatures/getReports?page=${page}&limit=${10}&search=${searchValue}`
      );
      setUsers(
        res?.data.map((item) => ({
          ...item,
          fullname: item.user ? item.user?.fullname : "",
          phone: item.user ? item.user?.phone : "",
          assessmentType: item?.ibsAssessment?.assessmentType,
          assessmentDate: formatDate(item?.ibsAssessment?.assessmentDate),
          fileLocation: item?.ibsAssessment?.fileLocation,
          action: { edit: false, delete: true },
        }))
      );
      setLoading(false);
      setPageCount(res?.totalPage);
      setTotalData(res?.totalData);
      setPerPage(res?.perPage);
      setCurrentPage(res?.currentPage);
    } catch (err) {
      console.error("Error:", err);
      setLoading(true);
    }
  };
  function formatDate(dateString) {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
  }
  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      // setIsModalOpen(true);
    } else if (type === "edit") {
      // setEditModal(true);
      // setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedReportContent = await patch(
        `/api/dashboard/appFeatures/deleteReport/${row._id}`
      );
      setMessage(deletedReportContent?.message);
      toastMessage(deletedReportContent?.message, "success");
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleDisplay = (row) => {
    console.log("Display", row);
    console.log("doc", row?.document);
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Test Reports</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by document name"}
                debounceTime={1000}
              />
            </div>
          </div>
          <CustomTable
            data={users}
            columns={reportTableColumns}
            handlePageChange={(page) => handleChange(page)}
            handleDelete={handleDelete}
            handleDisplay={handleDisplay}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
    </>
  );
};

export default Users;