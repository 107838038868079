import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, postFiles, patch } from "../../config/axios";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";

// Define columns and form fields for specialities
const specialityTableColumns = [
    { id: "S.No", label: "S.No",minWidth: 70,align: "left",
      },
  { id: "speciality", label: "Speciality Name", align: "left" },
  { id: "hindiSpeciality", label: "Hindi Speciality Name", align: "left" },
  {
    id: "specialityIcon",
    label: "Speciality Icon",
    minWidth: 70,
    align: "center",
    type: "IMAGE",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  { id: "action", label: "Actions", align: "center" }
];

const specialityFormFields = [
  {
    name: "speciality",
    label: "Speciality Name",
    type: "text",
    required: true
  },
  {
    name: "hindiSpeciality",
    label: "Hindi Speciality Name",
    type: "text",
    required: true
  },
  {
    name: "specialityIcon",
    label: "Speciality Icon",
    type: "file",
    required: true,
    accept: "image/*"
  }
];

const Specialities = () => {
  const [specialities, setSpecialities] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteSpeciality, setDeleteSpeciality] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const fetchSpecialities = async (searchValue) => {
    setLoading(true);
    try {
      const response = await get(
        `/api/dashboard/inPersonFeatures/get-speciality?page=${page}&limit=${10}&search=${searchValue}`
      );

      setSpecialities(
        response?.data.map((item) => ({
          ...item,
          action: { edit: true, delete: true },
        }))
      );
      setLoading(false);
      setPageCount(response?.totalPage);
      setTotalData(response?.totalData);
      setPerPage(response?.perPage);
      setCurrentPage(response?.currentPage);
    } catch (error) {
      console.log("Error fetching specialities", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search === "") {
      fetchSpecialities("");
    } else if (debouncedSearch) {
      fetchSpecialities(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteSpeciality = async (row) => {
    try {
      const deletedSpeciality = await patch(
        `/api/dashboard/inPersonFeatures/delete-speciality/${row._id}`
      );
      setMessage(deletedSpeciality?.message);
      toastMessage(deletedSpeciality?.message, "success");
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
  };

  const handleActive = async (row, active) => {
    console.log("row==", row);
    const { _id: id } = row;
    
    setLoading(true);
    try {
      let response = await put(
        `/api/dashboard/inPersonFeatures/update-speciality/${row}`,
        {
          active: active,
        }
      );

      const specialityTitle = response?.data?.speciality;
      const message = active
        ? `${specialityTitle} successfully activated.`
        : `${specialityTitle} successfully deactivated.`;
      setLoading(false);
      setMessage(message);
      toastMessage(message, "success");
    } catch (error) {
      setLoading(false);
      toastMessage("Error updating status", "error");
    }
  };

  const handleSearch = (searchText) => {
    setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
      setEditData({});
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteSpeciality(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = async (formData, isEditing, id) => {
    setLoading(true);
    try {
      if (isEditing) {
        let form = new FormData();
        const { ...data } = formData;
        if (formData.specialityIcon instanceof File) {
          const validImageTypes = ["image/svg+xml", "image/png", "image/jpeg"];
          if (!validImageTypes.includes(formData.specialityIcon.type)) {
            setLoading(false);
            toastMessage("Speciality Icon (.svg, .png, .jpeg) is required", "error");
            return;
          }
          form.append("file", formData?.specialityIcon);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.specialityIcon = res.data.url;
        }

        let response = await put(
          `/api/dashboard/inPersonFeatures/update-speciality/${id}`,
          data
        );
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        const validImageTypes = ["image/svg+xml", "image/png", "image/jpeg"];
        if (!validImageTypes.includes(formData.specialityIcon.type)) {
          setLoading(false);
          toastMessage("Speciality Icon (.svg, .png, .jpeg) is required", "error");
          return;
        }
        
        let form = new FormData();
        form.append("file", formData?.specialityIcon);
        const res = await postFiles("/api/app/user/uploadImage", form);
        const { ...data } = formData;
        data.specialityIcon = res.data.url;
        
        const ret = await post("/api/dashboard/inPersonFeatures/add-speciality", data);
        setMessage("Successfully added");
        toastMessage(ret.data.message, "success");
        setIsModalOpen(false);
      }
      fetchSpecialities("");
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Doctor Specialities</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by speciality name"}
                debounceTime={1000}
              />
            </div>

            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add speciality
            </Button>
          </div>
          <CustomTable
            data={specialities}
            columns={specialityTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            totalData={totalData}
            perPage={perPage}
            currentPage={currentPage}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteSpeciality}
        data={deleteSpeciality}
      />
      <FormModal
        accept="image/*"
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={specialityFormFields}
        header={editModal ? "Edit Speciality" : "Add Speciality"}
        initialData={editData}
        isEditing={editModal}
      />
    </>
  );
};

export default Specialities;