export const dietTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 300,
    align: "left",
  },
  {
    id: "category.title",
    label: "Category",
    minWidth: 70,
    align: "left",
  },
  {
    id: "subCategory.title",
    label: "Sub-category",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "image",
  //   label: "Image",
  //   minWidth: 70,
  //   align: "center",
  //   type: "IMAGE",
  // },

  // {
  //   id: "subCategory?.title",
  //   label: "Sub Category",
  //   minWidth: 70,
  //   align: "center",
  //   type: "left",
  // },
  {
    id: "assets",
    label: "Attachment",
    minWidth: 70,
    align: "center",
    type: "VIDEO",
  },
  {
    id: "thumbnail",
    label: "Thumbnail",
    minWidth: 70,
    align: "center",
    type: "thumbnail",
  },
  {
 id: "createdAt",
    label: "Publishing Date",
    minWidth: 70,
    align: "center",
  },
  {
    id: "details",
    label: "Details",
    minWidth: 70,
    align: "center",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];
